.contentcreate {
    width: 26rem;
    padding-top: 40px;
    padding-bottom: 10px;
}
.buttonscreate {
    padding-right: 15px;
    padding-bottom: 10px;
}
.bluebutton {
    padding-right: 30px;
    padding-left: 30px;
}
