.contentedit {
    width: 26rem;
    padding-top: 40px;
    padding-bottom: 10px;
}
.buttonsedit {
    padding-right: 15px;
    padding-bottom: 10px;
}
.cancel {
    color: black;
}

.bluebuttonbave {
    padding-right: 35px;
    padding-left: 35px;
}
