.cancel {
    color: black;
}
.contentdelete {
    padding-right: 110px;
    padding-bottom: 15px;
}
.buttonsdelete {
    padding-right: 20px;
}
.redbutton {
    padding-right: 30px;
    padding-left: 30px;
}
